import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import classnames from 'classnames';
import axios from 'cccisd-axios';

const Boilerplate = window.cccisd.boilerplate;
const requiredErrorMessage = 'The Club Access Code is required';

export default class Form extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        initialValue: PropTypes.string,
    };

    static defaultProps = {
        initialValue: '',
    };

    state = {
        clubAccessId: this.props.initialValue,
        isVerifyingCode: false,
        codeChanged: false,
        errorMessage: this.props.initialValue ? '' : requiredErrorMessage,
        clubInfo: {},
    };

    componentDidMount() {
        if (this.props.initialValue) {
            this.onChange(this.props.initialValue);
        }
    }

    setInvalidClubMessage = (error = null) => {
        this.setState({
            isVerifyingCode: false,
            errorMessage: error ? error : 'The Club Access Code you entered is invalid. Please try again.',
            clubInfo: {},
        });
    };

    onChange = async value => {
        const lastUse = new Date().getTime();
        this.lastUse = lastUse;
        value = value.trim();

        if (!value) {
            this.setState({
                codeChanged: true,
                clubAccessId: '',
                isVerifyingCode: false,
                errorMessage: requiredErrorMessage,
            });
        } else {
            this.setState({
                codeChanged: true,
                clubAccessId: value,
                isVerifyingCode: true,
                errorMessage: '',
            });

            // wait a bit just to not run ajax request so often
            await new Promise(resolve => setTimeout(resolve, 500));

            if (this.lastUse !== lastUse) {
                return;
            }

            if (!/^\d{6}$/.test(value)) {
                this.setInvalidClubMessage();
                return;
            }

            const result = await axios.get(Boilerplate.route('get.club.info', { accessCodeId: value }));

            if (this.lastUse !== lastUse) {
                return;
            }

            if (result.data.status !== 'success' || result.data.club.participatingThisYear !== 1) {
                this.setInvalidClubMessage(result.data.error);
            } else {
                this.setState({
                    isVerifyingCode: false,
                    errorMessage: '',
                    clubInfo: result.data.club,
                });
            }
        }
    };

    onSubmit = () => {
        if (this.state.errorMessage) {
            return;
        }

        this.props.onSubmit(this.state.clubInfo);
    };

    render() {
        const { isVerifyingCode, clubAccessId, codeChanged, errorMessage, clubInfo } = this.state;
        const showError = codeChanged && errorMessage;

        let helpBlock = null;
        if (isVerifyingCode) {
            helpBlock = <Spinner />;
        } else if (showError) {
            helpBlock = errorMessage;
        } else if (codeChanged) {
            helpBlock = (
                <span>
                    Your club: <b>{clubInfo.label}</b>
                </span>
            );
        }

        return (
            <form onSubmit={this.onSubmit}>
                <div className={classnames('mb-3', showError && 'is-invalid')}>
                    <label className="form-label">Please enter the 6-digit Club Access Code you were provided.</label>
                    <input
                        className="form-control"
                        placeholder="Club Access Code"
                        name="clubAccessId"
                        value={clubAccessId}
                        onChange={e => this.onChange(e.target.value)}
                        autoComplete="off"
                        autoFocus
                        maxLength="6"
                    />
                    {helpBlock && <div className="form-text mt-2">{helpBlock}</div>}
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ width: '100%' }}
                    disabled={isVerifyingCode || errorMessage}
                    onClick={this.onSubmit}
                >
                    Confirm Club Access Code
                </button>
            </form>
        );
    }
}
